* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-style: normal;
}

html,
body {
  min-height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

button {
  cursor: pointer;
}

.centered-tooltip {
  text-align: center;
  padding-top: 10px;
  line-height: 1.4;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  z-index: 30000 !important;
}
